/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// APIs
import { API_PRESSES, API_UPLOADS } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PressSection() {
  /* ******************************** CONSTANTS ******************************* */

  const PageNumbers = [1];
  const { language } = useI18next();

  /* ********************************** HOOKS ********************************* */

  // State
  const [activePage, setActivePage] = useState(1);
  const [pressData, setPressData] = useState();
  // Other hooks like reux or localization ...

  /* --------------------------------- HELPERS -------------------------------- */
  async function getPresses() {
    const { data } = await axios(API_PRESSES(language));
    setPressData(data);
  }
  // Effect
  useEffect(() => {
    getPresses();
  }, []);
  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  const PressMap = pressData?.data?.map(({ id, attributes }) => {
    const { title, description, url, images } = attributes;
    return (
      <Col
        key={id}
        lg={4}
        md={6}
        className="press-section-item "
        onClick={() => navigate(url)}
      >
        <div className="press-section-item-container">
          <div className="press-item__img">
            <img
              src={API_UPLOADS(images?.data[0].attributes.formats.small?.url)}
              alt="presses images"
            />
          </div>
          <h3 className="press-item__title">{title}</h3>
          <p className="press-item__description">{description}</p>
        </div>
      </Col>
    );
  });
  function GetPagination() {
    return (
      <Row align="center" justify="center" className="pagination-container">
        {PageNumbers.map((pageNum) => (
          <button
            type="button"
            key={pageNum}
            onClick={() => setActivePage(pageNum)}
            className={`${activePage === pageNum ? 'active' : ''}`}
          >
            {pageNum}
          </button>
        ))}
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="press-section" fluid>
      <Row
        align="center"
        justify="center"
        className="press-section-items scroll-reveal"
      >
        {PressMap}
      </Row>
      <GetPagination />
    </Container>
  );
}

PressSection.propTypes = {};

PressSection.defaultProps = {};

export default PressSection;
